import React from "react"
import Guide from "../../../components/guide2020"

const IndexPage = ({data}) => (
  <Guide
    Year={"2020"}
    Section={"hus"}
    ProductName={"husforsikring"}
    Entity={"hus"}
    Attributes={40}
    Stars5={{
      need: "For deg som vil være aller best sikret",
      description: 'De aller beste husforsikringene - 5 defaqto stjerner',
      terms: [
        {t: "Fullverdigaranti",c: "Må ha fullverdigaranti"},
        {t: "Gjenoppføring over 75%",c: "Må være dekket"},
        {t: "Hage og basseng",c: "Må være dekket over 500 000 kr"},
        {t: "Merutgifter offentlig påbud",c: "Må være dekket for 2 000 0000 kr"},
        {t: "Følgeskader utett tak og vegg",c: "Må tilbys"},
        {t: "Følgeskader utett våtrom",c: "Må tilbys"},
        {t: "Følgeskader håndverker",c: "Må tilbys"},
        {t: "Skadedyr og insekter",c: "Må tilbys"},
        {t: "Sopp- og råteskader",c: "Må tilbys"},
      ]
    }}
    Stars4={{
      need: "For deg som vil ha en veldig god husforsikring, men ikke trenger de aller høyeste dekningene i markedet",
      description: 'Veldig gode husforsikringer - 4 defaqto stjerner',
      terms: [
        {t: "Fullverdigaranti",c: "Må ha fullverdigaranti"},
        {t: "Gjenoppføring over 75%",c: "Må være dekket"},
        {t: "Hage og basseng",c: "Må være dekket over 400 000 kr"},
        {t: "Merutgifter offentlig påbud",c: "Må være dekket for 2 000 0000 kr"},
        {t: "Følgeskader utett tak og vegg",c: "Må tilbys"},
        {t: "Følgeskader utett våtrom",c: "Må tilbys"},
        {t: "Følgeskader håndverker",c: "Må tilbys"},
        {t: "Skadedyr og insekter",c: "Må tilbys"},
        {t: "Sopp- og råteskader",c: "Må tilbys"},
      ]
    }}
    Stars3={{
      need: "For deg som trenger en standard husforsikring, uten markedets beste dekninger",
      description: 'Gode husforsikringer - 3 defaqto stjerner',
      terms: [
      ]
    }}
  />
)

export default IndexPage